export default {
  MyEvents: 'أحداثي',
  MyProfile: 'تفاصيلي',
  ContactUS: 'اتصل بنا',
  CallTemplate: 'نموذج الاتصال',
  NewEvent: 'إنشاء حدث جديد',
  NewGroup: 'إنشاء مجموعة',
  GroupName: 'أسم المجموعة',
  EditGroupName: 'تحرير اسم المجموعة',
  NumberGroupMember: 'أعضاء المجموعة',
  Edit: 'تعديل',
  EditingInvitees: 'تحرير المدعوين',
  WhoAttending: 'الذين سيأتون؟',
  AttendingPerInvitee: 'أدخل عدد المشاركين لكل مدعو',
  Delete: 'حذف',
  Saving: 'حفظ',
  DeleteLang: 'حذف اللغة',
  AddNewInvitee: 'أضف المدعو',
  GuestDetails: 'تفاصيل الضيف',
  GuestAnswer: 'الضيف يجيب',
  fullName: 'الاسم الكامل',
  phoneNumber: 'رقم الهاتف',
  countOfInvites: 'عدد الضيوف',
  NumberGuestAnswer: 'عدد الحاضرين',
  outOf: 'من',
  Progress: 'تقدم',
  Export: 'تصدير إلى Excel',
  GeneralSettings: 'الاعدادات العامة',
  eventSettings: 'تفاصيل الحدث',
  AdvancedSettings: 'إعدادات متقدمة',
  Personalize: 'أضفى طابع شخصي',
  Terms: 'شروط',
  PrivacyTerms: 'سياسة الخصوصية',
  EventName: 'اسم الحدث',
  EventNamePerLang: 'اسم الحدث في ',
  ExampleEventName: 'زفاف حاييم وعدي',
  EventLocationName: 'اسم مكان الحدث (لصفحة الدعوة)',
  EventLocationGoogle: 'اختر موقع الحدث لرابط ويز',
  EventDate: 'تاريخ الحدث',
  Date: 'تاريخ',
  EventTime: 'وقت الحدث',
  Add: 'يضيف',
  Cancel: 'الغاء',
  Ok: 'تأكيد',
  Send: 'إرسال',
  Close: 'أغلق',
  UploadInvitation: 'وضع أمر',
  InvitationPreview: 'دعوة',
  ContactInvitees: 'الاتصال بالضيوف',
  DefaultLanguage: 'اللغة الافتراضية',
  EventLanguages: 'لغات الحدث',
  EditInvitation: 'تحرير الدعوة',
  Language: 'لغة',
  MainLanguage: 'عربيه',
  hebrew: 'اللغة العبرية',
  english: 'انجليزي',
  russian: 'الروسية',
  arabic: 'عربيه',
  he: 'اللغة العبرية',
  en: 'انجليزي',
  ru: 'الروسية',
  ar: 'عربيه',
  work_days: 'الأحد الخميس',
  all_days: 'كل أيام الأسبوع',
  specialMeals: 'أطباق خاصة',
  ChooseSpecialMeals: 'اختر الوجبات',
  no_special_dish: 'ليست هناك حاجة لأطباق خاصة',
  glatt: 'جلات',
  vegetarian: 'نباتي',
  gluten_free: 'خالي من الغلوتين',
  vegan: 'نباتي',
  no_need_to_ask: 'لا حاجة للسؤال عن النقل',
  some_guests: 'سأطلب من بعض الضيوف',
  all_guests: 'سأطلب من الجميع',
  Transport: 'وسائل النقل',
  Meal: 'طبق',
  GetPlacesName: 'اكتب اسم المدينة',
  AddName: 'ENTER - يضيف',
  CreateOne: 'تمت إضافة خيار آخر',
  ClassGroup: 'مجموعة',
  Previous: 'السابق',
  Next: 'التالي',
  Finish: 'انهاء',
  FieldRequired: 'حقل مطلوب',
  RequireNum: 'يجب أن يكون رقمًا',
  MaxField: 'يحتوي هذا الحقل على {num} حرفًا كحد أقصى',
  MinField: 'يحتوي هذا الحقل على {num} أحرف على الأقل.',
  WelcomeToAnitaLee: 'اهلا وسهلا!',
  HappyToBePartOfYourEvent: 'سعيد لكوني جزء من هذا الحدث الخاص بك!',
  PleaseSignUp: 'أنشئ حسابك',
  CreateAccount: 'إنشاء حساب',
  YourEventYourControl: 'الحدث الخاص بك - سيطرتك! سجل الان',
  IAgree: 'لقد قرأت ووافقت على',
  AlreadyHaveAccount: 'هل لديك حساب؟',
  AccountExist: '*الحساب موجود مسبقا',
  Or: 'أو',
  FirstEvent: 'حدثك الأول؟',
  Register: 'يسجل',
  ConfirmPasswordNotMatch: 'كلمات السر لا تتطابق',
  NewPassword: 'كلمة المرور الجديدة',
  Password: 'كلمة المرور',
  ConfirmPassword: 'تأكيد كلمة المرور',
  ChangePassword: 'تغيير كلمة المرور',
  ForgotPassword: 'هل نسيت كلمة السر؟',
  PasswordChangeSuccessful: 'تم تغيير الرقم السري بنجاح',
  ForgotPasswordMsg:
    'أدخل عنوان البريد الإلكتروني الذي قمت بالتسجيل به ، وسنرسل لك تعليمات لإعادة تعيين كلمة المرور الخاصة بك.',
  BackToLogin: 'العودة لتسجيل الدخول',
  Login: 'تسجيل الدخول',
  Logout: 'تسجيل خروج',
  PasswordGuide: [
    'تتكون كلمة المرور من 4 إلى 20 حرفًا ',
    'رقم واحد على الأقل (0-9) أو رمز خاص واحد على الأقل (!،@..)',
    'حرف كبير واحد على الأقل باللغة الإنجليزية (A-Z)',
    'حرف صغير واحد على الأقل باللغة الإنجليزية (a-z)',
  ],
  WeakPassword: 'كلمة مرور ضعيفة',
  NotValidEmail: 'بريد إلكتروني خاطئ',
  errorNewEvent: 'تعذر إنشاء الحدث ، يرجى المحاولة مرة أخرى لاحقًا',
  errorManageEvents: 'هناك شئ خاطئ، يرجى المحاولة فى وقت لاحق',
  errorNewGroup: 'اسم المجموعة هذا موجود بالفعل',
  invalid_guests_coming: 'عدد المستجيبين أكبر من عدد المدعوين',
  search: 'بحث',
  advancedFilter: 'فلتر متقدم',
  filter: 'فلتر',
  status: 'وضع',
  delete: 'حذف',
  inProgress: 'في معالجة',
  approved: 'قادم',
  declined: 'لا يأتي',
  Yes: 'نعم',
  No: 'لا',
  Navigation: 'ملاحة',
  AddToCalendar: 'أضف إلى اليوميات',
  wrongNumber: 'رقم غير صحيح',
  pending: 'الانتظار',
  viewCount: 'شاهد الدعوة',
  changedDecision: 'تم تغيير قرار',
  ifArriveQA: 'هل ستأتي?',
  AmountPeople: 'كمية من الناس',
  comments: 'ملاحظات',
  messageBeforeDelete: 'هل أنت متأكد أنك تريد حذف؟',
  oops: 'أُووبس',
  cantDeleteGuestsInProcess: 'لم يكن بالإمكان حذف المدعوين الذين بدأنا بالفعل فيهم عملية',
  cantDeletePaidEvent: 'لا يمكن حذف حدث تم دفع ثمنه بالفعل',
  messageBeforeStartProccess: 'هل أنت متأكد أنك تريد بدء عملية لـ',
  messageForNumberOfGroups: 'يحتوي الحدث الخاص بك على',
  ActiveOrders: 'أوامر نشطة',
  FinishedOrders: 'الطلبات مغلقة',
  Details: 'تفاصيل',
  Invitees: 'ضيوف',
  RecentResponse: 'أجاب مؤخرا',
  StartDateRsvp: 'سيبدأ فترة الإجابة عن الدعوة في تاريخ',
  StartDateTip: 'نصيحتنا - ابدأ قبل 14 يومًا من الحدث',
  EventStatus: 'حالة الحدث',
  TypeOfOrderPackage: 'الحزمة المطلوبة',
  OurPackages: 'أسعارنا',
  ExplainAboutThePackages: 'تتضمن جميع حزمنا نظامًا لإدارة الضيف والتخصيص',
  GuideMessageSelectPackage: 'يرجى تحديد نوع المراسلة SMS/Whatsapp وعدد المدعوين',
  ViewAll: 'مشاهدة الكل',
  ChangedDecision: 'غيروا رأيهم',
  attendingByGroups: 'التصنيف يأتي من قبل المجموعات',
  Dashboards: 'لوحات القيادة',
  Dashboard: 'الصفحة الرئيسية',
  GuestsList: 'قائمة الضيوف',
  CRM: 'CRM',
  Analytics: 'تحليلات',
  eCommerce: 'التجارة الإلكترونية',
  Calendar: 'تقويم',
  Chat: 'محادثة',
  Email: 'بريد إلكتروني',
  incorrectIdentificationDetails: 'معلومات التعريف التي تم إدخالها غير صحيحة',
  Groups: 'المجموعات',
  ManageGroups: 'تحديد المجموعات',
  AdvantageGroupsManagement: ['إدارة الضيوف', 'إحصائيات المجموعات', 'بحث سريع عن الضيوف', 'اتخاذ قرارات صحيحة'],
  CallTemplatesExplain: [
    'لقد قمنا بإعداد قوالب حوارية آلية لك بناءً على المعلومات التي قدمتها لنا.',
    'يمكنك تحرير قوالب الحوار وفقًا لتفضيلاتك.',
  ],
  ListenCall: '  للاستماع إلى المحادثة ، انقر فوق  ',
  MessageTemplatesExplain: [
    'لقد أعددنا قوالب الرسائل الافتراضية بناءً على المعلومات التي قدمتها لنا.',
    'يمكنك تحرير الرسائل بأي لغة.',
  ],
  InvitationPreviewExp: 'رابط دعوة شخصي لكل ضيف أسفل كل رسالة.',
  ViewInvitation: 'عرض الدعوة',
  MessageTemplates: 'قالب رسائل الضيف',
  SettingEvent: 'إعدادات الحدث',
  MessageTemplate: 'نموذج الرسائل',
  CallTemplates: 'نموذج المكالمات',
  MyOrders: 'طلباتي',
  MyAccount: 'حساب',
  Notification: 'إشعارات',
  Today: 'اليوم',
  Yesterday: 'أمس',
  basic_package: 'أساسي',
  premium_package: 'مميز',
  basic_desc: 'الرسائل فقط',
  premium_desc: 'الرسائل والمكالمات',
  basic_package_include_sms: [
    'إدارة الضيوف',
    'إدارة المصروفات',
    'دعوة رقمية للحدث',
    'رسائل قابلة للتخصيص',
    '3 جولات لتأكيد الحضور عبر SMS',
    'الاتجاهات عبر الرسائل القصيرة (SMS) في يوم الحدث',
    'رسالة شكر في اليوم التالي عبر SMS',
  ],
  basic_package_include_whatsapp: [
    'إدارة الضيوف',
    'إدارة المصروفات',
    'دعوة رقمية للحدث',
    'رسائل قابلة للتخصيص',
    '3 جولات لتأكيد الحضور عبر WhatsApp',
    'الاتجاهات عبر الرسائل القصيرة (SMS) في يوم الحدث',
    'رسالة شكر في اليوم التالي عبر SMS',
  ],
  basic_package_not_include: ['مكالمة هاتفية قابلة للتخصيص', '3 جولات من المكالمات التلقائية'],
  premium_package_include_sms: [
    'إدارة الضيوف',
    'إدارة المصروفات',
    'دعوة رقمية للحدث',
    'رسائل قابلة للتخصيص',
    '3 جولات لتأكيد الحضور عبر SMS',
    'الاتجاهات عبر الرسائل القصيرة (SMS) في يوم الحدث',
    'رسالة شكر في اليوم التالي عبر SMS',
    'مكالمة هاتفية قابلة للتخصيص',
    '3 جولات من المكالمات التلقائية',
  ],
  premium_package_include_whatsapp: [
    'إدارة الضيوف',
    'إدارة المصروفات',
    'دعوة رقمية للحدث',
    'رسائل قابلة للتخصيص',
    '3 جولات لتأكيد الحضور عبر WhatsApp',
    'الاتجاهات عبر الرسائل القصيرة (SMS) في يوم الحدث',
    'رسالة شكر في اليوم التالي عبر SMS',
    'مكالمة هاتفية قابلة للتخصيص',
    '3 جولات من المكالمات التلقائية',
  ],
  premium_package_not_include: [],
  ActivePackage: 'On',
  Active: 'نشيط',
  NotActive: 'غير فعال',
  For: 'ل',
  Total: 'المجموع',
  TotalPaid: 'مجموع المبالغ المدفوعة:',
  ExistingPackageAmount: 'قيمة الباقة الحالية:',
  VerifyEmail: 'قم بتأكيد بريدك الألكتروني',
  NotReceiveEmailQA: 'لم تحصل على البريد الإلكتروني؟',
  Resend: 'إعادة الإرسال',
  ChooseGuestAmount: 'اختر عدد الضيوف',
  AddLang: 'إضافة لغة',
  BuyPackage: 'شراء الحزمة',
  UpgradePackage: 'ترقية الحزمة',
  FullyUsedPackage: 'تم استخدام العبوة بالكامل',
  CantAddInvitee: 'لا يمكن إضافة ضيوف إضافيين',
  GroupsClassificationChart: 'تصنيف المجموعات',
  NoData: 'لايوجد بيانات',
  NoHaveInvitees: 'لا ضيوف',
  noNotifications: 'لا إشعارات',
  Notifications: 'إشعارات',
  OverView: 'ملخص',
  EventDetails: 'بيانات عن الحدث',
  EventDetailsMessage: 'عدد الأشخاص المدعوين إلى الحدث: ',
  invitationMessage: 'إشعار أولي',
  call: 'مكالمة تلقائية',
  NotAnswerMessage: 'أنت لم تؤكد وصولك بعد',
  DirectionMessage: 'تذكير وإرشاد',
  ThanksMsg: 'رسالة شكر',
  limitCountOfInvitesMessage: 'عدد المدعوين أقل من عدد المستجيبين',
  HappyToSeeYou: 'سنكون سعداء لرؤيتك',
  ResponseSuccess: 'تم استلام ردك',
  Invoice: 'فاتورة',
  List: 'قائمة',
  Preview: 'معاينة',
  Management: 'إدارة',
  Settings: 'إعدادات',
  Support: 'يدعم',
  AttendingNotZero: 'عدد الحضور غير صالح',
  PhoneNumExist: 'رقم الهاتف هذا موجود بالفعل',
  importContacts: 'استيراد جهات الاتصال',
  ImportGuests: 'استيراد الضيوف',
  submitImportContacts: 'يستورد',
  UploadExcelFile: 'استيراد إكسل',
  RegularExcelTemplate: 'إكسل العادي',
  IplanExcelTemplate: 'إكسل لـ Iplan',
  importContactsSettingsDesc: 'حدد الإعدادات التي سيتم تطبيقها على المدعوين المختارين',
  AskTransportation: 'اسأل عن المواصلات؟',
  AskSpecialMeals: 'اسأل عن تفضيلات النظام الغذائي؟',
  pleaseWait: 'انتظر من فضلك',
  errorFetchingGoogleContacts: 'تعذر استيراد جهات الاتصال ، يرجى المحاولة مرة أخرى لاحقًا',
  thanksForChoosingUs: 'شكرا لك لاختيارنا',
  createYourExcitingEvent: 'اصنع حدثك المثير هنا!',
  WhatsAppMessages: ' طلب WhatsApp',
  SmsMessages: 'طلب SMS',
  ThanksMessages: 'رسائل الشكر',
  Reminder: 'رسائل تذكير',
  CallMessages: 'المكالمات',
  sent: 'مرسل',
  expensesDescription: 'اسم المصاريف',
  supplierName: 'اسم المورد',
  totalPayment: 'المبلغ الإجمالي',
  paidAmount: 'مدفوع بالفعل',
  leftToPay: 'المبلغ المتبقي للدفع',
  eventExpenses: 'نفقاتي',
  requiredTerms: '*قم بتأكيد الشروط',
  InvitationToEvent: 'دعوة لحضور حدث',
  invitationUploadOption: ' يمكنك تحميل دعوتك لهذا الحدث (ممكن لاحقا)',
  page_not_found: 'الصفحة غير موجودة',
  back_to_home: 'العودة للصفحة الرئيسية',
  sent_to_email: 'أرسلنا إلى عنوان بريدك الإلكتروني:',
  verification_link: 'رابط التحقق',
  please_verify_email: 'يرجى التحقق من عنوان بريدك الإلكتروني عن طريق النقر على الرابط الذي أرسلناه إليكم.',
  invalidPhoneNumber: 'رقم غير صالح',
  PhoneNumberDuplicate: 'رقم هاتف مكرر',
  maxNumOfGroup: 'الحد الأقصى لعدد المجموعات هو 10',
  package_price: 'سعر الطرد: ',
  ForRsvp: 'تأكيد أو رفض الحضور: ',
  ForDirection: 'تعليمات الوصول: ',
  invalid_coupon: 'رمز القسيمة خاطئ',
  payment_method_credit_card: 'بطاقة إئتمان',
  payment_method_bit: 'Bit',
  Order: 'طلبية',
  Confirmation: 'تأكيد',
  How_pay: 'اختر طريقة الدفع',
  EnterPromoCode: 'ادخل رمز العرض',
  Apply: 'تطبيق',
  OrderDetails: 'تفاصيل الطلب',
  TotalForPay: 'الإجمالي',
  Payment: 'الدفع',
  Payment_completed_Successfully: 'تم الدفع بنجاح!',
  Thank_you_for_your_purchase: 'شكراً لاختيارك لنا! رقم تأكيد الطلب هو: ',
  Go_To_Event_Page: 'انتقل إلى صفحة الحدث',
  bad_date: 'تاريخ المناسبة قريب جدًا',
  wrong_package: 'الباقة المختارة غير موجودة',
  payment_failed: 'فشل الدفع',
  payment: 'دفع',
  agree_invitation_start_date: 'أؤكد تاريخ بدء تأكيدات الحضور: ',
  agree_message_templates: 'أؤكد قوالب الرسائل التي سيتم إرسالها للضيوف باللغات التالية: ',
  agree_call_templates: 'أؤكد قوالب المكالمات التي سيتم إجراؤها للضيوف باللغات التالية: ',
  confirm_all_fields: '* يرجى تأكيد جميع الحقول',
  must_choose_from_google_location: 'يرجى اختيار موقع الحدث من القائمة',
  eventDateInPast: 'تاريخ الحدث قد مضى',
  CouponDiscount: 'خصم القسيمة',
  orderNumber: 'رقم الأمر: ',
  GroupsNav: 'مجموعات',
  GuestNav: 'ضيوف',
  Messages: 'رسائل',
  Calls: 'المكالمات',
  PaymentNav: 'الدفع',
  navTiming: 'التوقيت',
  ImportContactsViaGoogle: 'استيراد من جوجل',
  CreateGroupsNav: 'أنشئ المجموعات المناسبة لحدثك',
  SelectTimingNav: 'حدد أوقات الإرسال لتأكيدات الحضور',
  AddGuestsNav: 'أضف المدعوين الخاصين بك',
  EditMessagesNav: 'قم بتخصيص قالب الرسائل',
  EditCallsNav: 'قم بتخصيص قالب المكالمات',
  ChoosePackageNav: 'قم بشراء الحزمة الأكثر ملاءمة لحدثك',
  Attending: 'قادمون',
  NotAttending: 'لا قادمون',
  PlanChanged: 'تغيرت الخطط؟',
  WeAreSorry: 'نحن آسفون جداً',
  WeUpdateWrongNumberInSystem: 'شكراً على التوضيح',
  YouChoseWhatsAppMessage: 'لقد اخترت باقة تشمل رسائل واتساب',
  WhatsAppMessageWarning:
    'يرجى ملاحظة أنه إذا كان هناك مشكلة في إرسال رسالة WhatsApp إلى ضيف معين بسبب أسباب خارجة عن سيطرتنا، سنقوم بإرسال رسالة SMS بدلاً من رسالة WhatsApp.',
  SortBy: 'ترتيب حسب',
  addToGroup: 'إضافة إلى المجموعة',
  countOfInvitesMustBeUntil10: 'الحد الأقصى للعدد هو 10',
  countOfInvitesBetween1to10: 'يجب أن يكون الرقم بين 1 إلى 10',
  ImportYourInviteeFromExcel: 'استيراد المدعوين من إكسل',
  ChooseForEachColumnFromExcel: 'اختر لكل حقل الاسم العمود المناسب من ملف Excel الخاص بك',
  recognizeErrorsFromExcel: 'لقد حددنا مشكلة في استيراد المدعوين التاليين',
  moreInfoAboutErrors: 'لمزيد من المعلومات حول الأخطاء، انقر على',
  fixTheExcelErrors: 'يرجى تصحيح الأخطاء وإعادة استيراد الملف',
  SuccessfullyAdded: 'تمت إضافة ',
  SuccessfullyAddedOneGuest: 'تمت إضافته بنجاح',
  InvalidFullNameFormatColumn: 'العمود المختار لاسم المدعو لا يتوافق',
  isValidFormatPhoneColumn: 'العمود المختار لرقم الهاتف لا يتوافق',
  isValidFormatCountOfInviteeColumn: 'العمود المختار لعدد المدعوين لا يتوافق',
  ClearAll: 'احذف الكل',
  AllInviteeAlreadyExist: 'جميع المدعوين من الملف تم إضافتهم بالفعل',
  InvalidFileType: 'ملف غير صالح، يرجى تحميل ملف إكسل من نوع xlsx',
  InvalidFile: 'ملف غير صالح',
  NeedHelp: 'تحتاج مساعدة؟ راسلنا بالبريد الإلكتروني!',
  StartRsvpDataInPast: 'تاريخ بدء تأكيد الحضور لا يمكن أن يكون في الماضي',
  AddInvitees: 'أضف المدعوين',
  NotEnoughData: 'لا توجد بيانات كافية',
  AssignGroupsToInvitees: 'قم بتعيين المجموعات للمدعوين',
  CreateGroups: 'إنشاء مجموعات',
  NoDataYet: 'لا توجد بيانات بعد',
  viewedInvitation: 'تم مشاهدة الدعوة',
  notViewedInvitation: 'لم تُشاهد الدعوة',
  FileIsEmpty: 'الملف فارغ',
  PhoneNumberNotChanged: 'لم يتم تغيير رقم الهاتف، للمتابعة يجب تغيير رقم الهاتف',
  EditPhoneNumber: 'تحرير رقم الهاتف',
  ChangedYourMind: 'غيرت رأيك؟',
  FileMustHaveTitles: 'يجب أن يحتوي الملف على عناوين',
  NavigationByWaze: 'الملاحة بواسطة ويز',
  NavigationByGoogleMaps: 'الملاحة بواسطة خرائط جوجل',
  partiallyComing: 'حضور جزئي',
  actionTiming: 'وقت الإرسال',
  sameDay: 'في يوم الحدث',
  dayBefore: 'قبل يوم من الحدث',
  twoDaysBefore: 'قبل يومين من الحدث',
  dayAfter: 'بعد يوم من الحدث',
  twoDaysAfter: 'بعد يومين من الحدث',
  daysBeforeEvent: 'أيام قبل الحدث',
  daysAfterEvent: 'أيام بعد الحدث',
  startFrom: 'تبدأ من ',
  activityDays: ['من الأحد إلى الخميس 09:00-20:30', 'الجمعة 09:00-14:30'],
  selectActivityDays: 'أيام نشاط الخدمة التي يمكن اختيارها هي:',
  Agree: 'موافق',
  WhatsAppMessageTemplateExplain: 'يرجى ملاحظة أن الرسالة الأولى المرسلة على واتساب ستكون كالتالي: ',
  WhatsAppTemplate: `مرحبًا😊
لقد تمت دعوتك إلى الحدث {eventName}.
لتلقي الدعوة حيث يمكنك تأكيد حضورك - انقر على الزر أدناه "استلام الدعوة" ⬇

*تم الإرسال بواسطة Anita Lee، برنامج لتأكيد الحضور للمناسبات.`,

  AfterWhatsAppButtonClick:
    'بعد الضغط على زر "استلام الدعوة"، سيتلقى المدعو رسالة رد محددة في قالب الرسائل (قابلة للتعديل) مع رابط لصفحة دعوة الحدث، حيث يمكنه تأكيد حضوره.',
  edit_details_in_event_settings: 'يمكنك تعديل التفاصيل في إعدادات الحدث',
  edit_messages_in_all_languages: 'يمكنك تعديل الرسائل بجميع اللغات التي اخترتها في قالب الرسائل',
  digital_invitation_is_ready: 'لقد أعددنا دعوة رقمية للحدث...',
  whenTimingWillSending: 'متى سيتم إرساله؟',
  round: 'الجولة ',
  NoHaveClosestAction: 'الحدث انتهى',
  closestAction: 'أقرب توقيت',
  upgrade: 'ترقية',
  edit_details_in_message_template: 'يمكنك تعديل المحتوى في قالب الرسالة',
  how_messages_look_to_you: 'كيف تبدو لك هذه الرسائل؟',
  we_set_when_each_round_will_sent: 'لقد حددنا متى سيتم إرسال كل جولة',
  edit_timing: 'يمكنك تغيير التوقيت لاحقا',
  starting_in_just_a_moment: 'سيبدأ بعد لحظة...',
  we_prepare_everything_you_need: 'نحن أعددنا تقريبًا كل ما تحتاجه,',
  lets_see: 'دعنا نرى',
  AddManually: 'إضافة يدويًا',
  we_do_almost_all_job: 'يبدو أننا أنجزنا تقريباً كل العمل',
  you_need_only_add_guests: '- ما عليك سوى إضافة الضيوف',
  PleaseNote: 'يرجى ملاحظة',
  TimingCanChange: 'قد يتغير توقيت إرسال تأكيدات الحضور',
  TemplatesNeedManualEdit: 'يجب تحديث تفاصيل التغيير في قوالب الرسائل/المكالمات يدويًا',
  DateOrTimeChanged: 'تم تغيير تاريخ و/أو وقت الحدث',
  only_messages: 'رسائل فقط',
  calls_with_messages: 'رسائل ومكالمات',
  interests_package: 'الباقة التي تهمني تشمل...',
  add_guests_later: 'إضافة الضيوف لاحقًا',
  Calling: '...يتصل',
  Accept: 'الرد',
  Decline: 'رفض',
  how_call_listening: 'فكرنا أيضًا في كيفية سماع المكالمات...',
  edit_call_in_all_languages: 'يمكنك تعديل مقدمة المكالمة بجميع اللغات التي اخترتها في قالب المكالمات',
  edit_details_in_call_template: 'يمكنك تعديل مقدمة المكالمة في قالب المكالمات',
  invitation_page_loading_text: 'دعوة خاصة في طريقها إليك...',
  googleSignInButton: 'تسجيل الدخول باستخدام Google',
  paymentNotAllowedInWebView: 'الدفع متاح فقط في الموقع',
  pleaseUseWebsiteForPayment: 'يرجى استخدام الموقع لإتمام الدفع.',
  continueToWebsite: 'الانتقال إلى الموقع',
  Invitee: 'المدعو'
}
