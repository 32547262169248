<template>
  <div>
    <!-- Guest response history -->
    <GuestResponse
      v-if="showPlansChanged && Object.keys(guestResponse).length > 0"
      :guest-response="guestResponse"
      :show-plans-changed.sync="showPlansChanged"
    />
    <!-- QA for guest -->
    <div
      v-if="!showPlansChanged"
      style="max-width: 270px;"
    >
      <v-card-text class="text-center">
        <div class="font-weight-semibold mb-2 text-lg">
          {{ $t('ifArriveQA') }}
        </div>
        <div class="d-flex justify-center align-center yes-no-selection">
          <v-radio-group
            v-model="guestStatus"
            class="small-radio mt-0 font-weight-bold"
            row
          >
            <v-radio
              :label="$t('Yes')"
              :value="guestStatusMapping.COMING"
              class="ma-0 pa-0 mx-3"
            ></v-radio>
            <v-radio
              :label="$t('No')"
              :value="guestStatusMapping.NOT_COMING"
              class="ma-0 pa-0 mx-3"
            ></v-radio>
          </v-radio-group>
        </div>
      </v-card-text>
      <!-- Only show other questions if guest is coming -->
      <div v-if="guestStatus === guestStatusMapping.COMING">
        <!-- Number of Guests -->
        <v-col
          cols="9"
          class="justify-center align-center"
          style="text-align: center"
        >
          <span class="font-weight-semibold text-lg">{{ $t('AmountPeople') }}</span>
        </v-col>
        <v-col
          cols="9"
          class="justify-center align-center mt-n3 mb-4"
          style="text-align: center"
          dir="rtl"
        >
          <div class="d-flex justify-center align-center text-center">
            <v-btn
              icon
              @click="incrementNumArrive"
            >
              <v-avatar
                size="30"
                class="v-avatar-light-bg primary--text"
              >
                <v-icon color="primary">
                  {{ icons.mdiPlus }}
                </v-icon>
              </v-avatar>
            </v-btn>
            <div class="mx-3 text-2xl font-weight-semibold">
              {{ numArrive }}
            </div>

            <v-btn
              icon
              color="primary"
              @click="decrementNumArrive"
            >
              <v-avatar
                size="30"
                class="v-avatar-light-bg primary--text"
              >
                <v-icon>{{ icons.mdiMinus }}</v-icon>
              </v-avatar>
            </v-btn>
          </div>
        </v-col>
        <!-- Meal option -->
        <div
          v-if="meals.length > 0"
        >
          <div
            v-for="(invitee, index) in numArrive"
            :key="index"
            class="invitee-meal-selection"
          >
            <div class="invitee-title font-weight-bold text-lg">
              {{ $t('Invitee') }} #{{ index + 1 }}
            </div>
            <v-radio-group
              v-model="selectedMeals[index]"
              column
              class="meal-selection-group"
              :mandatory="true"
            >
              <label
                v-for="(meal, mealIndex) in meals"
                :key="mealIndex"
                class="meal-option d-flex"
              >
                <v-icon
                  color="primary"
                  class="meal-icon"
                  @click="clickOnMealIcon(index, meal)"
                >
                  mdi-silverware-fork-knife
                </v-icon>
                <v-radio
                  :label="meal.translations[lang]"
                  :value="meal.id"
                  class="meal-radio font-weight-bold"
                ></v-radio>
              </label>
            </v-radio-group>
          </div>
        </div>
        <!-- Transport option -->
        <div
          v-if="transports.length > 0"
          class="mt-2 pt-0"
        >
          <v-select
            v-model="selectedTransport"
            :items="transports"
            :label="`${$t('Transport')}?`"
            clearable
            outlined
          ></v-select>
        </div>
        <!-- Guest comment -->
        <div>
          <v-textarea
            v-model="guestComment"
            :label="`${$t('comments').charAt(0).toUpperCase() + $t('comments').slice(1)}?`"
            auto-grow
            filled
            rows="2"
            row-height="20"
            class="mb-0 mt-3"
            :counter="40"
            :maxlength="40"
          ></v-textarea>
        </div>
      </div>

      <!-- Send form -->
      <v-card-text>
        <v-row
          align="center"
          justify="center"
        >
          <v-col
            cols="8"
            class="mt-0"
          >
            <v-btn
              block
              color="primary"
              class="mt-4"
              :disabled="guestStatus === null || preview"
              @click="sendGuestResponse"
            >
              <span class="font-weight-semibold">
                {{ $t('Send') }}
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </div>

    <!-- Wrong Number Option -->
    <div
      v-if="!showPlansChanged"
      class="d-flex align-center justify-center"
    >
      <v-icon
        size="20"
        class="me-1"
        color="primary"
      >
        {{ icons.mdiAlertOutline }}
      </v-icon>
      <v-btn
        color="transparent"
        text
        class="ma-0 pa-0"
        @click="wrongNumber"
      >
        <span class="text-base primary--text"> {{ $t('wrongNumber') }}? </span>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { guestStatusMapping } from '@/composables/useGuestStatuses'
import { i18n } from '@/plugins/i18n/index'
import store from '@/store'
import { mdiAlertOutline, mdiMinus, mdiPlus } from '@mdi/js'
import { computed, watch } from '@vue/composition-api'
import GuestResponse from './GuestResponse.vue'

export default {
  components: {
    GuestResponse,
  },
  setup() {
    const state = store.state.invitationStore

    const guestStatus = computed({
      get: () => state.guestStatus,
      set: value => store.commit('invitationStore/setGuestStatus', value),
    })

    const numArrive = computed({
      get: () => state.numArrive,
      set: value => store.commit('invitationStore/setNumArrive', value),
    })

    const meals = computed(() => state.meals)

    const selectedMeals = computed({
      get: () => state.selectedMeals,
      set: value => store.commit('invitationStore/setSelectedMeals', value),
    })

    const transports = computed(() => state.transports)

    const selectedTransport = computed({
      get: () => state.selectedTransport,
      set: value => store.commit('invitationStore/setSelectedTransport', value),
    })

    const guestComment = computed({
      get: () => state.guestComment,
      set: value => store.commit('invitationStore/setGuestComment', value),
    })

    const guestResponse = computed(() => state.guestResponse)

    const showPlansChanged = computed({
      get: () => state.showPlansChanged,
      set: value => store.commit('invitationStore/setShowPlansChanged', value),
    })

    const lang = computed(() => i18n.locale)

    const sendGuestResponse = () => {
      store.dispatch('invitationStore/sendGuestResponse')
    }

    const wrongNumber = () => {
      store.dispatch('invitationStore/wrongNumber')
    }

    const clickOnMealIcon = (inviteeIndex, chosenMeal) => {
      const updatedMeals = [...selectedMeals.value]
      updatedMeals[inviteeIndex] = chosenMeal.id
      store.commit('invitationStore/setSelectedMeals', updatedMeals)
    }

    const incrementNumArrive = () => {
      if (numArrive.value < 20) {
        store.commit('invitationStore/setNumArrive', numArrive.value + 1)
      }
    }

    const decrementNumArrive = () => {
      if (numArrive.value > 1) {
        store.commit('invitationStore/setNumArrive', numArrive.value - 1)
      }
    }

    const icons = {
      mdiPlus,
      mdiMinus,
      mdiAlertOutline,
    }

    const preview = computed(() => state.preview)

    // Ensure selectedMeals array matches numArrive
    watch(numArrive, newVal => {
      if (selectedMeals.value.length > newVal) {
        store.commit('invitationStore/setSelectedMeals', selectedMeals.value.slice(0, newVal))
      } else {
        const additionalMeals = new Array(newVal - selectedMeals.value.length).fill(null)
        store.commit('invitationStore/setSelectedMeals', [...selectedMeals.value, ...additionalMeals])
      }
    })

    return {
      guestStatus,
      guestStatusMapping,
      numArrive,
      meals,
      selectedMeals,
      transports,
      selectedTransport,
      guestComment,
      guestResponse,
      showPlansChanged,
      sendGuestResponse,
      wrongNumber,
      clickOnMealIcon,
      incrementNumArrive,
      decrementNumArrive,
      icons,
      preview,
      lang,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/styles.sass';

.small-radio label {
  font-size: 10px !important;
  padding-left: 0px !important;
}

.v-input--radio-group.v-input--radio-group--row .v-radio {
  margin-right: 0px;
  margin-left: 0px;
}

::v-deep .yes-no-selection .v-input--selection-controls__input {
  margin-left: 3px !important;
  margin-right: 3px !important;
}

::v-deep .yes-no-selection .v-input--selection-controls .v-radio > .v-label {
  font-size: 19px;
}

.meal-option {
  display: flex;
  margin-bottom: 12px;
  gap: 10px;

  @include ltr() {
    padding-left: 3rem;
  }
  @include rtl() {
    padding-right: 3rem;
  }
}

.meal-icon {
  font-size: 20px;
}

.invitee-title {
  text-align: start;
}

::v-deep .meal-option .theme--light.v-icon:focus::after {
  opacity: 0 !important;
}
</style>
