<template>
    <div>
      <!-- Image Section -->
      <v-row align="center" justify="center">
        <v-col cols="11">
          <div class="image-container -mt-10">
            <PreloadedImage
              @imageLoaded="handleImageLoaded"
              :src="img ? img : require('@/assets/images/curly-heart.png')"
              alt="invitation image"
              class="invitation-image"
            >
            </PreloadedImage>
          </div>
        </v-col>
      </v-row>
      <!-- Event Details -->
      <v-card-title class="d-flex align-center justify-center" style="word-break: break-word">
        <div class="font-weight-bold" style="font-size: 23px">
          {{ eventName }}
        </div>
      </v-card-title>
      <v-card-subtitle class="d-flex flex-column align-center justify-center font-weight-semibold text-lg">
        <div class="mb-1">
          {{ locationName }}
        </div>
        <div class="d-flex justify-center">
          <span class="ma-3">{{ formattedDate }}</span>
          <span class="ma-3">{{ eventTime }}</span>
        </div>
      </v-card-subtitle>
    </div>
  </template>
  
  <script>
  import PreloadedImage from '@/components/PreloadedImage.vue';
import { i18n } from '@/plugins/i18n/index';
import store from '@/store';
import { computed } from '@vue/composition-api';
import moment from 'moment';

  export default {
    components: {
      PreloadedImage,
    },
    setup(props) {
      const state = store.state.invitationStore
  
      const img = computed(() => state.img)
      const eventName = computed(() => state.names[i18n.locale] || state.names[state.lang] || state.names[state.defaultLang])
      const locationName = computed(() => state.locationName)
      const date = computed(() => state.date)
      const formattedDate = computed(() => moment(date.value).format('DD/MM/YY'))
      const eventTime = computed(() => {
        const eventDate = new Date(date.value)
        const hours = eventDate.getHours().toString().padStart(2, '0')
        const minutes = eventDate.getMinutes().toString().padStart(2, '0')
        return `${hours}:${minutes}`
      })
      
      const handleImageLoaded = () => {
        store.dispatch(`invitationStore/handleImageLoaded`)
      };
      
      return {
        img,
        eventName,
        locationName,
        formattedDate,
        eventTime,
        handleImageLoaded,
      }
    },
  }
  </script>
  
  <style scoped>
  .image-container {
    position: relative;
    width: 100%;
  }
  
  .invitation-image {
    width: 100%;
    max-width: 100%;
    height: auto;
    object-fit: contain;
    border-radius: 10px;
  }
  </style>
  