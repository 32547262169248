<template>
  <div
    class="invitation-container-all"
    :style="`${$vuetify.breakpoint.xsOnly ? 'background: white;' : ''}`"
  >
    <InvitationPageLoader v-if="isInvitationPageLoading && !tourGuide" />
    <div
      v-show="!isInvitationPageLoading"
      :dir="$i18n.locale === 'ar' || $i18n.locale === 'he' ? 'rtl' : 'ltr'"
    >
      <v-overlay
        v-show="isResponseLoading"
        opacity="0.35"
      >
        <v-progress-circular
          indeterminate
          size="64"
        ></v-progress-circular>
      </v-overlay>
      <!-- Invitation card -->
      <div class="d-flex justify-center mt-5">
        <div
          :style="`width: 100%; ${$vuetify.breakpoint.xsOnly ? '' : 'max-width: 450px;'}`"
          class="auth-v1 justify-center align-center"
          style="text-align: -webkit-center"
        >
          <v-card
            class="auth-card mb-10 rounded-xl"
            flat
            :class="{ 'ma-0 pa-0': $vuetify.breakpoint.mdAndDown }"
          >
            <EventDetails />
            <v-form :disabled="isResponseLoading">
              <GuestForm />
              <NavigationButtons />
            </v-form>
          </v-card>
        </div>
      </div>
    </div>
    <InvitationBottomButtons
      v-if="!isInvitationPageLoading || tourGuide"
      :preview="preview"
      :available-locales="Object.keys(names)"
      :default-locale="lang"
    />
  </div>
</template>

<script>
import InvitationBottomButtons from '@/components/EventDetails/InvitationBottomButtons.vue'
import store from '@/store'
import invitationStore from '@/store/modules/invitationStore'
import { computed, onUnmounted, watch } from '@vue/composition-api'
import EventDetails from './EventDetails.vue'
import GuestForm from './GuestForm.vue'
import NavigationButtons from './NavigationButtons.vue'
import InvitationPageLoader from './InvitationPageLoader.vue'

export default {
  components: {
    InvitationBottomButtons,
    EventDetails,
    GuestForm,
    NavigationButtons,
    InvitationPageLoader,
  },
  props: [
    'paramPreview',
    'paramMeals',
    'paramTransports',
    'paramNames',
    'paramLocationName',
    'paramLocationGeo',
    'paramDate',
    'paramImg',
    'paramLang',
    'paramDefaultLang',
    'paramGuestResponse',
    'paramTourGuide',
    'paramSendInviteeRequest',
    'paramSendWrongNumberRequest',
  ],
  setup(props) {
    const INVITATION_STORE_MODULE_NAME = 'invitationStore'

    if (!store.hasModule(INVITATION_STORE_MODULE_NAME)) {
      store.registerModule(INVITATION_STORE_MODULE_NAME, invitationStore, { preserveState: false })
    }

    onUnmounted(() => {
      if (store.hasModule(INVITATION_STORE_MODULE_NAME)) {
        store.commit(`${INVITATION_STORE_MODULE_NAME}/resetState`, props)
        store.unregisterModule(INVITATION_STORE_MODULE_NAME)
      }
    })

    store.commit(`${INVITATION_STORE_MODULE_NAME}/updateStoreFromProps`, props)

    const state = store.state[INVITATION_STORE_MODULE_NAME]

    // Computed properties
    const isInvitationPageLoading = computed(() => {
      const checkIfPropsLoaded = Object.keys(props).every(prop => props[prop] !== undefined)

      return !checkIfPropsLoaded || state.isLoadingInvitationImage
    })

    const isResponseLoading = computed(() => state.isResponseLoading)

    const tourGuide = computed(() => state.tourGuide)
    const names = computed(() => state.names)
    const lang = computed(() => state.lang)
    const preview = computed(() => state.preview)

    store.dispatch(`${INVITATION_STORE_MODULE_NAME}/loadLastGuestResponse`)
    store.dispatch(`${INVITATION_STORE_MODULE_NAME}/loadLangPage`)

    watch(
      () => props,
      newProps => {
        store.commit(`${INVITATION_STORE_MODULE_NAME}/updateStoreFromProps`, newProps)
        store.dispatch(`${INVITATION_STORE_MODULE_NAME}/loadLastGuestResponse`)

        // store.dispatch(`${INVITATION_STORE_MODULE_NAME}/loadLangPage`)
      },
      { deep: true },
    )

    return {
      isInvitationPageLoading,
      isResponseLoading,
      tourGuide: tourGuide.value,
      names: names.value,
      lang: lang.value,
      preview: preview.value,
    }
  },
}
</script>

<style scoped>
.invitation-container-all {
  padding: 0;
  width: 100%;
  overflow-x: hidden;
  height: 100%;
}

.auth-card {
  min-height: 96vh;
}
</style>
