<template>
  <InvitationPage
    :class="` ${$vuetify.breakpoint.xsOnly ? '' : 'mt-2'}`"
    :param-tour-guide="tourGuide"
    :param-preview="true"
    :param-meals="$store.state.eventData.eventSpecialMeals"
    :param-transports="transports"
    :param-names="$store.state.eventData.names"
    :param-location-name="$store.state.eventData.location.eventLocationName"
    :param-location-geo="$store.state.eventData.location"
    :param-date="$store.state.eventData.date"
    :param-img="getFullImageUrl($store.state.eventData.image)"
    :param-lang="$i18n.locale"
    :param-default-lang="$store.state.eventData.defaultLang"
    :param-guest-response="{}"
    :param-send-invitee-request="() => {}"
    :param-send-wrong-number-request="() => {}"
  ></InvitationPage>
</template>

<script>
import InvitationPage from '@/components/Invitation/InvitationPage.vue';
import store from '@/store';
import { getFullImageUrl } from '@core/utils/ImageFromApi';
import { mdiClose } from '@mdi/js';

export default {
  components: { InvitationPage },
  props: {
    tourGuide: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      icons: {
        mdiClose,
      },
      getFullImageUrl,
    }
  },
  computed: {
    transports() {
      return store.state.eventData?.transports?.map(el => el.name) || []
    },
  },
}
</script>
