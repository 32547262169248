export default {
  MyEvents: 'My Events',
  MyProfile: 'My Profile',
  ContactUS: 'Contact Us',
  CallTemplate: 'Call template',
  NewEvent: 'Create new event',
  NewGroup: 'Create Group',
  GroupName: 'Group Name',
  EditGroupName: 'Edit Group Name',
  NumberGroupMember: 'Group Members',
  Edit: 'Edit',
  EditingInvitees: 'Editing invitees',
  WhoAttending: "Who's attending?",
  AttendingPerInvitee: 'Enter the number of attending for each invitee',
  Delete: 'Delete',
  Saving: 'Save',
  DeleteLang: 'Delete language',
  AddNewInvitee: 'Add invitee',
  GuestDetails: 'Guest details',
  GuestAnswer: 'Guest response',
  fullName: 'Full name',
  phoneNumber: 'Phone number',
  countOfInvites: 'Number of invitees',
  NumberGuestAnswer: 'Attendees',
  outOf: 'out of',
  Progress: 'Progress',
  Export: 'Export To Excel',
  GeneralSettings: 'General settings',
  eventSettings: 'Event details ',
  AdvancedSettings: 'Advanced settings',
  Personalize: 'Personalize',
  Terms: 'Terms',
  PrivacyTerms: 'Privacy policy',
  EventName: 'Event Name',
  EventNamePerLang: 'Event Name in ',
  ExampleEventName: "Haim and Adi's Wedding",
  EventLocationName: 'Event location name(for invitation page)',
  EventLocationGoogle: 'Select Event Location for Waze Link',
  EventDate: 'Event Date',
  Date: 'Date',
  EventTime: 'Event Time',
  Add: 'Add',
  Cancel: 'Cancel',
  Ok: 'Ok',
  Send: 'Send',
  Close: 'Close',
  UploadInvitation: 'Upload invitation',
  InvitationPreview: 'Invitation',
  ContactInvitees: 'Contact invitees',
  DefaultLanguage: 'Default language',
  EventLanguages: 'Event Languages',
  EditInvitation: 'Editing invitation',
  Language: 'Language',
  Notifications: 'Notifications',
  Today: 'Today',
  Yesterday: 'Yesterday',
  MainLanguage: 'English',
  basic_package: 'Basic',
  premium_package: 'Premium',
  basic_desc: 'Messages only',
  premium_desc: 'Messages and calls',
  basic_package_include_sms: [
    'Guest management',
    'Expense management',
    'Digital invitation',
    'Customizable messages',
    "3 rounds RSVP's via SMS",
    'Direction on event day via SMS',
    'Appreciation the day after via SMS',
  ],
  basic_package_include_whatsapp: [
    'Guest management',
    'Expense management',
    'Digital invitation',
    'Customizable messages',
    "3 rounds RSVP's via WhatsApp",
    'Direction on event day via SMS',
    'Appreciation the day after via SMS ',
  ],
  basic_package_not_include: ['Customizable phone call', '3 rounds of automatic calls'],
  premium_package_include_sms: [
    'Guest management',
    'Expense management',
    'Digital invitation',
    'Customizable messages',
    "3 rounds RSVP's via SMS",
    'Direction on event day via SMS',
    'Appreciation the day after via SMS',
    'Customizable phone call',
    '3 rounds of automatic calls',
  ],
  premium_package_include_whatsapp: [
    'Guest management',
    'Expense management',
    'Digital invitation',
    'Customizable messages',
    "3 rounds RSVP's via WhatsApp",
    'Direction on event day via SMS',
    'Appreciation the day after via SMS',
    'Customizable phone call',
    '3 rounds of automatic calls',
  ],
  premium_package_not_include: [],
  ActivePackage: 'On',
  Active: 'Active',
  NotActive: 'Not active',
  ChooseGuestAmount: 'Choose the number of invitees',
  For: 'For',
  Total: 'Total',
  TotalPaid: 'Total paid:',
  ExistingPackageAmount: 'Your Package Value:',
  VerifyEmail: 'Verify your email',
  NotReceiveEmailQA: "Didn't get the mail?",
  Resend: 'Resend',
  AddLang: 'Add language',
  BuyPackage: 'Buy package',
  UpgradePackage: 'Upgrade package',
  FullyUsedPackage: 'The package was fully used',
  CantAddInvitee: "It's not possible to add additional guests",
  GroupsClassificationChart: 'Groups Overview',
  attendingByGroups: 'Attending By Groups',
  NoData: 'No data',
  NoHaveInvitees: 'No guests',
  noNotifications: 'No notifications',
  OverView: 'Overview',
  EventDetails: 'Event Details',
  EventDetailsMessage: 'Number of invitees at your event: ',
  invitationMessage: 'Invitation',
  call: 'Automatic call',
  NotAnswerMessage: 'not answer yet',
  DirectionMessage: 'Reminder & Direction',
  ThanksMsg: 'Thanks',
  limitCountOfInvitesMessage: 'The number of invitees is less than the number of respondents',
  HappyToSeeYou: 'We will be happy to see you',
  ResponseSuccess: 'Got it, thanks!',
  hebrew: 'Hebrew',
  english: 'English',
  russian: 'Russian',
  arabic: 'Arabic',
  he: 'Hebrew',
  en: 'English',
  ru: 'Russian',
  ar: 'Arabic',
  work_days: 'Sunday-Thursday',
  all_days: 'All days of the week',
  specialMeals: 'Special dishes',
  ChooseSpecialMeals: 'Choose meals',
  no_special_dish: 'No need to ask',
  glatt: 'Glatt',
  vegetarian: 'vegetarian',
  gluten_free: 'Gluten free',
  vegan: 'vegan',
  no_need_to_ask: 'No need to ask',
  some_guests: 'I will ask some of the guests',
  all_guests: 'I will ask all the guests',
  Transport: 'Transportation',
  Meal: 'Meal',
  GetPlacesName: 'Type the name of the city',
  AddName: 'ENTER - To add',
  CreateOne: 'Add another option',
  ClassGroup: 'Group',
  Previous: 'Previous',
  Next: 'Next',
  Finish: 'Finish',
  FieldRequired: 'Required field',
  RequireNum: 'must be a number',
  MaxField: 'The Max Character field must be under {num} characters',
  MinField: 'The Min Character field must be above {num} characters',
  WelcomeToAnitaLee: 'Welcome!',
  HappyToBePartOfYourEvent: 'Proud to be part of your event!',
  PleaseSignUp: 'Create Your Account',
  CreateAccount: 'Create Account',
  YourEventYourControl: 'Your event - your control! Register now',
  IAgree: 'I agree to ',
  AlreadyHaveAccount: 'Already have an account?',
  AccountExist: '*This account already exist',
  Or: 'Or',
  FirstEvent: 'Your first event?',
  Register: 'Register',
  ConfirmPasswordNotMatch: 'The passwords do not match',
  NewPassword: 'New Password',
  Password: 'Password',
  ConfirmPassword: 'Confirm Password',
  ChangePassword: 'Change Password',
  ForgotPassword: 'Forgot Password?',
  PasswordChangeSuccessful: 'Password changed successfully',
  ForgotPasswordMsg: "Enter your email and we'll send you instructions to reset your password",
  BackToLogin: 'Back To login',
  Login: 'Login',
  Logout: 'Logout',
  PasswordGuide: [
    'Choose 4-20 char for password',
    'At least one digit (0-9) or one special char (!،@..)',
    'At least one uppercase letter (A-Z)',
    'At least one lowercase (a-z)',
  ],
  WeakPassword: 'Weak Password',
  NotValidEmail: 'Not valid Email',
  errorNewEvent: 'The event could not be created, please try again later',
  errorManageEvents: 'Something went wrong, please try again later',
  errorNewGroup: 'This group name already exists',
  invalid_guests_coming: 'The number of respondents is higher than the number of invitees',
  search: 'Search',
  advancedFilter: 'Advanced Filter',
  filter: 'Filter',
  status: 'Status',
  delete: 'Delete',
  inProgress: 'in proccess',
  approved: 'Attending',
  declined: 'Not Attending',
  Yes: 'Yes',
  No: 'No',
  Navigation: 'Navigation',
  AddToCalendar: 'Add to calendar',
  wrongNumber: 'Wrong Number',
  pending: 'Pending',
  viewCount: 'Viewed',
  changedDecision: 'Indecisive',
  ifArriveQA: 'Will you be attending?',
  AmountPeople: 'How many will you be?',
  comments: 'notes',
  messageBeforeDelete: 'Are you sure you want to delete?',
  oops: 'Oops...',
  cantDeleteGuestsInProcess: 'It was not possible to delete invitees for whom we had already started a process',
  cantDeletePaidEvent: 'A paid event cannot be deleted',
  messageBeforeStartProccess: 'Are you sure you want to start a process for',
  messageForNumberOfGroups: 'your event have',
  ActiveOrders: 'Active orders',
  FinishedOrders: 'Closed orders',
  Details: 'Details',
  Invitees: 'Invitees',
  RecentResponse: 'Recently Responded',
  StartDateRsvp: 'The RSVPs will start',
  StartDateTip: 'Our tip - start 14 days before the event',
  EventStatus: 'Event status',
  TypeOfOrderPackage: 'Ordered package',
  OurPackages: 'Our packages',
  ExplainAboutThePackages: 'All our packages include a system for guest management and customization',
  GuideMessageSelectPackage: 'Please select messaging type SMS/Whatsapp and the amount of invitees',
  ViewAll: 'View All',
  ChangedDecision: 'Changed Decision',
  Dashboards: 'Dashboards',
  Dashboard: 'Overview',
  GuestsList: 'Guests list',
  CRM: 'CRM',
  Analytics: 'Analytics',
  eCommerce: 'eCommerce',
  Calendar: 'Calendar',
  Chat: 'Chat',
  Email: 'Email address',
  incorrectIdentificationDetails: 'Incorrect identification details',
  Groups: 'Groups',
  ManageGroups: 'Groups Manager',
  AdvantageGroupsManagement: [
    'Guest management',
    'Statistics by groups',
    'Quick search of guests',
    'Making true decisions',
  ],
  CallTemplatesExplain: [
    'We prepared default call templates based on your information.',
    'You can edit the conversation templates.',
  ],
  ListenCall: 'To listen to the conversation, click ',
  MessageTemplatesExplain: [
    'We prepared default message templates based on your information.',
    'You can edit the messages in any language.',
  ],
  InvitationPreviewExp: 'Personalized invitation link for each guest below every message.',
  ViewInvitation: 'View invitation',
  MessageTemplates: 'Message templates',
  SettingEvent: 'Event settings',
  MessageTemplate: 'Messages templates',
  CallTemplates: 'Call templates',
  MyOrders: 'My orders',
  MyAccount: 'My account',
  Invoice: 'Invoice',
  List: 'List',
  Preview: 'Preview',
  Management: 'Management',
  Settings: 'Settings',
  Support: 'Support',
  AttendingNotZero: 'The number of attending is not valid',
  PhoneNumExist: 'Phone number already exists',
  importContacts: 'Import contacts',
  ImportGuests: 'Import invitees',
  submitImportContacts: 'Import',
  UploadExcelFile: 'Import Excel',
  RegularExcelTemplate: 'Regular Excel',
  IplanExcelTemplate: 'Iplan Excel',
  importContactsSettingsDesc: 'Select settings that will apply to selected invitees',
  AskTransportation: 'Ask about transportation?',
  AskSpecialMeals: 'Ask about dietary preferences?',
  pleaseWait: 'Please wait',
  errorFetchingGoogleContacts: 'Unable to import contacts, please try again later',
  thanksForChoosingUs: 'Thanks for Choosing Us!',
  createYourExcitingEvent: 'Create your exciting event here!',
  WhatsAppMessages: 'WhatsApp invitations',
  SmsMessages: 'SMS invitations',
  ThanksMessages: 'Thanks messages',
  Reminder: 'Reminder messages',
  CallMessages: 'Call',
  sent: 'Sent',
  expensesDescription: 'Expense Name',
  supplierName: 'Supplier Name',
  totalPayment: 'Total Amount',
  paidAmount: 'Amount paid',
  leftToPay: 'Amount to paid',
  eventExpenses: 'My expenses',
  requiredTerms: '*Confirm the terms',
  InvitationToEvent: 'Invitation image',
  invitationUploadOption: 'Upload your invitation to the even(not must)',
  page_not_found: 'Page Not Found',
  back_to_home: 'Back To Home',
  sent_to_email: 'We have sent to your email address:',
  verification_link: 'a verification link.',
  please_verify_email: 'Please verify your email address by clicking on the link we have sent to you.',
  invalidPhoneNumber: 'Invalid phone number',
  PhoneNumberDuplicate: 'Duplicated phone number',
  maxNumOfGroup: 'The maximum number of groups is 10',
  package_price: 'Package Price: ',
  ForRsvp: 'Invitation & RSVP: ',
  ForDirection: 'For directions: ',
  invalid_coupon: 'Wrong coupon code',
  payment_method_credit_card: 'Credit Card',
  payment_method_bit: 'Bit',
  Order: 'Order',
  Confirmation: 'Confirmation',
  How_pay: 'Choose a payment method',
  EnterPromoCode: 'Enter Promo Code',
  Apply: 'Apply',
  OrderDetails: 'Order Details',
  TotalForPay: 'Total',
  Payment: 'Payment',
  Payment_completed_Successfully: 'Payment Completed Successfully!',
  Thank_you_for_your_purchase: 'Thank you for choosing us! The order confirmation number is: ',
  Go_To_Event_Page: 'Go to Event Page',
  bad_date: 'The event date is too close',
  wrong_package: 'Selected package doesn’t exist',
  payment_failed: 'Payment failed',
  payment: 'Payment',
  agree_invitation_start_date: 'I confirm the start date of the RSVPs: ',
  agree_message_templates: 'I confirm the message templates that will be sent to guests in the following languages: ',
  agree_call_templates: 'I confirm the call templates that will be made to guests in the following languages: ',
  confirm_all_fields: '*Please confirm all fields',
  must_choose_from_google_location: 'Please choose the event location from the list',
  eventDateInPast: 'The event date has already passed',
  CouponDiscount: 'Coupon Discount',
  orderNumber: 'Order number: ',
  GroupsNav: 'Groups',
  GuestNav: 'Guest',
  Messages: 'Messages',
  Calls: 'Calls',
  PaymentNav: 'Payment',
  navTiming: 'Timing',
  ImportContactsViaGoogle: 'Import Google',
  CreateGroupsNav: 'Create the relevant groups for your event',
  SelectTimingNav: 'Set the sending times for the RSVPs',
  AddGuestsNav: 'Add yours invitees',
  EditMessagesNav: 'Customize the messages template',
  EditCallsNav: 'Customize the Calls template',
  ChoosePackageNav: 'Purchase the most suitable package for your event',
  Attending: 'Attending',
  NotAttending: 'Not attending',
  PlanChanged: 'Plans changed?',
  WeAreSorry: 'Our Apologies',
  WeUpdateWrongNumberInSystem: 'Thanks for the update',
  YouChoseWhatsAppMessage: 'You chose a package with WhatsApp messages',
  WhatsAppMessageWarning:
    "Please note, if there's an issue sending a WhatsApp message to a specific invitee due to reasons beyond our control, we'll send an SMS instead of WhatsApp.",
  SortBy: 'Sort by',
  addToGroup: 'Add to group',
  countOfInvitesMustBeUntil10: 'Maximum 10 invitees allowed',
  countOfInvitesBetween1to10: 'The number must be between 1 and 10',
  ImportYourInviteeFromExcel: 'Import invitees from excel',
  ChooseForEachColumnFromExcel: 'Choose for each field the appropriate column name from your Excel',
  recognizeErrorsFromExcel: 'We identified a problem with importing the following invitees',
  moreInfoAboutErrors: 'For more information about the errors, click on',
  fixTheExcelErrors: 'Please fix the errors and re-import the file',
  SuccessfullyAdded: 'Successfully added ',
  SuccessfullyAddedOneGuest: 'successfully added',
  InvalidFullNameFormatColumn: 'The selected full name column does not match the format',
  isValidFormatPhoneColumn: 'The selected phone number column does not match the format',
  isValidFormatCountOfInviteeColumn: 'The selected number of invitees column does not match the format',
  ClearAll: 'Clear all',
  AllInviteeAlreadyExist: 'All the invitees from the file have already been added',
  InvalidFileType: 'Invalid file, please upload an Excel file of type xlsx',
  InvalidFile: 'Invalid file',
  NeedHelp: 'Need help? Contact us!',
  StartRsvpDataInPast: 'Start RSVP date cannot be in the past',
  AddInvitees: 'Add Invitees',
  NotEnoughData: 'Not Enough Data',
  AssignGroupsToInvitees: 'Assign Groups to Invitees',
  CreateGroups: 'Create Groups',
  NoDataYet: 'No Data Yet',
  viewedInvitation: 'Viewed Invitation',
  notViewedInvitation: 'Not Viewed Invitation',
  FileIsEmpty: 'The file is empty',
  PhoneNumberNotChanged: 'Phone number not changed. To proceed, please change the phone number',
  EditPhoneNumber: 'Editing phone number',
  ChangedYourMind: 'Changed your mind?',
  FileMustHaveTitles: 'The file must include titles',
  NavigationByWaze: 'Navigation by Waze',
  NavigationByGoogleMaps: 'By Google Maps',
  partiallyComing: 'Partially attending',
  actionTiming: 'Timing',
  sameDay: 'On the event day',
  dayBefore: 'One day before the event',
  twoDaysBefore: 'Two days before the event',
  dayAfter: 'One day after the event',
  twoDaysAfter: 'Two days after the event',
  daysBeforeEvent: 'Days before the event',
  daysAfterEvent: 'Days after the event',
  startFrom: 'Start from ',
  activityDays: ['Sunday to Thursday 09:00-20:30', 'Friday 09:00-14:30'],
  selectActivityDays: 'The service activity days you can choose from are:',
  Agree: 'Agree',
  WhatsAppMessageTemplateExplain: 'Please note, the first message sent on WhatsApp will be as follows: ',
  WhatsAppTemplate: `Hi😊
You have been invited to {eventName}.
To receive the invitation where you can confirm your attendance - click the button below "Receive Invitation" ⬇

*Sent by Anita Lee, Event RSVP system.`,

  AfterWhatsAppButtonClick:
    'After the "Receive Invitation" button is clicked, the invitee will receive a return message defined in the message template (editable) with a link to the event invitation page, where they can confirm their attendance.',
  edit_details_in_event_settings: 'You can edit the details in the event settings',
  edit_messages_in_all_languages:
    'You can edit messages in all the languages you have selected in the message template',
  digital_invitation_is_ready: 'We have prepared a digital invitation for the event...',
  whenTimingWillSending: 'When will it be sent?',
  round: 'round ',
  NoHaveClosestAction: 'The event has ended',
  closestAction: 'Closest timing',
  upgrade: 'Upgrade',
  edit_details_in_message_template: 'You can edit the content in the message template',
  how_messages_look_to_you: 'How Do These Messages Look to You?',
  we_set_when_each_round_will_sent: 'We have set when each round will be sent',
  edit_timing: 'You can edit the timings later',
  starting_in_just_a_moment: 'Starting in just a moment...',
  we_prepare_everything_you_need: 'We prepared almost everything you need,',
  lets_see: "Let's see",
  AddManually: 'Add manually',
  we_do_almost_all_job: "It sounds like we've done almost all the work",
  you_need_only_add_guests: '- You only need to add the guests',
  PleaseNote: 'Please note',
  TimingCanChange: 'The timing of sending the RSVP may change',
  TemplatesNeedManualEdit: 'The details of the change in the messages/call templates need to be updated manually',
  DateOrTimeChanged: 'The date and/or time of the event has been changed',
  only_messages: 'Only messages',
  calls_with_messages: 'Messages and calls',
  interests_package: 'The package that interests me includes...',
  add_guests_later: 'add guests later',
  Calling: 'Calling...',
  Accept: 'Accept',
  Decline: 'Decline',
  how_call_listening: 'We also thought about how the calls will sound...',
  edit_call_in_all_languages:
    "You can edit the call introduction in all the languages you have selected in the 'call template'",
  edit_details_in_call_template: "You can edit the call introduction in the 'call template'",
  invitation_page_loading_text: 'A special invitation is on its way to you...',
  googleSignInButton: 'Sign in with Google',
  paymentNotAllowedInWebView: 'Payment Not Available in App',
  pleaseUseWebsiteForPayment: 'Please use the website to complete your payment.',
  continueToWebsite: 'Continue to Website',
  Invitee: 'Invitee'
}
