<template>
  <div>
    <app-card-actions
      action-collapse
      flat
      class="rounded-xl"
    >
      <template slot="title">
        {{ $t('advancedFilter') }}
      </template>
      <v-card-text>
        <v-row>
          <!-- status filter -->
          <v-col
            cols="12"
            sm="4"
          >
            <v-select
              v-model="statusFilter"
              :placeholder="$t('status')"
              :items="filteredStatus"
              :item-text="item => $t(item.title)"
              item-value="value"
              outlined
              dense
              clearable
              hide-details
              offset-y
              flat
              rounded
              class="user-search"
            ></v-select>
          </v-col>

          <!-- view invitation filter -->
          <v-col
            cols="12"
            sm="4"
          >
            <v-select
              v-model="viewedInvitationFilter"
              :placeholder="$t('viewCount')"
              :items="viewedInvitationFilterOptions"
              :item-text="item => $t(item.title)"
              item-value="value"
              outlined
              dense
              clearable
              hide-details
              offset-y
              flat
              rounded
              class="user-search"
            ></v-select>
          </v-col>

          <!-- groups filter -->
          <v-col
            v-if="$store.state.groupsData.length > 0"
            cols="12"
            sm="4"
          >
            <v-select
              v-model="groupsFilter"
              :placeholder="$t('ClassGroup')"
              :items="$store.state.groupsData"
              item-text="name"
              item-value="id"
              density="compact"
              outlined
              clearable
              dense
              hide-details
              attach
              multiple
              rounded
              menu-props="closeOnContentClick"
              class="user-search"
            />
          </v-col>

          <!-- special meals filter -->
          <v-col
            v-if="($store.state.eventData.specialMeal !== mealsTypes.NO_NEED_TO_ASK)"
            cols="12"
            sm="4"
          >
            <template>
              <v-select
                v-model="specialMealsFilter"
                :items="$store.state.eventData.eventSpecialMeals"
                :item-text="item => item.translations[$i18n.locale]"
                item-value="id"
                :placeholder="$t('specialMeals')"
                density="compact"
                multiple
                outlined
                dense
                hide-details
                clearable
                attach
                rounded
                class="user-search"
                menu-props="closeOnContentClick"
              />
            </template>
          </v-col>

          <!-- transport filter -->
          <v-col
            v-if="($store.state.eventData.transport !== 0)"
            cols="12"
            sm="4"
          >
            <template>
              <v-select
                v-model="transportFilter"
                :items="$store.state.eventData.transports"
                :item-text="item => item.translations[$i18n.locale] || item.name"
                item-value="id"
                :placeholder="$t('Transport')"
                density="compact"
                multiple
                outlined
                dense
                hide-details
                clearable
                rounded
                attach
                class="user-search"
                menu-props="closeOnContentClick"
              />
            </template>
          </v-col>

          <v-row
            align="center"
            :justify="$vuetify.breakpoint.smAndUp? 'end': 'center'"
            class="mx-2"
            no-gutters
          >
            <div class="py-4">
              <v-btn
                color="primary"
                rounded
                class="me-2"
                @click="fetchUserTable()"
              >
                {{ $t('filter') }}
              </v-btn>
              <v-btn
                outlined
                rounded
                color="primary"
                @click="resetAllFilters"
              >
                {{ $t('ClearAll') }}
              </v-btn>
            </div>
          </v-row>
        </v-row>
      </v-card-text>
    </app-card-actions>
  </div>
</template>
<script>
import useGuestStatuses from '@/composables/useGuestStatuses'
import useSpecialMealsTypes from '@/composables/useSpecialMealsTypes'
import store from '@/store'
import useUsersList from '@/views/apps/user/user-list/useUsersList'
import AppCardActions from '@core/components/app-card-actions/AppCardActions.vue'
import { computed, ref } from '@vue/composition-api'

export default {
  components: {
    AppCardActions,
  },
  setup() {
    const { mealsTypes } = useSpecialMealsTypes()
    const { statusOptions } = useGuestStatuses()
    const filteredStatus = ref()

    const handleStatus = () => {
      // Determine the title to filter out based on the progressStatus
      const filterTitle = store.state.eventData.progressStatus > 0 ? 'pending' : 'inProgress'

      // Filter out the object with the matching title
      filteredStatus.value = statusOptions.filter(
        option => option.title !== filterTitle && option.title !== 'viewCount',
      )
    }
    handleStatus()

    const statusFilter = computed({
      get() {
        return store.getters['inviteList/getStatusFilter']
      },
      set(value) {
        store.commit('inviteList/setStatusFilter', value)
      },
    })
    const groupsFilter = computed({
      get() {
        return store.getters['inviteList/getGroupFilter']
      },
      set(value) {
        store.commit('inviteList/setGroupFilter', value)
      },
    })
    const specialMealsFilter = computed({
      get() {
        return store.getters['inviteList/getSpecialMealsFilter']
      },
      set(value) {
        store.commit('inviteList/setSpecialMealsFilter', value)
      },
    })
    const transportFilter = computed({
      get() {
        return store.getters['inviteList/getTransportFilter']
      },
      set(value) {
        store.commit('inviteList/setTransportFilter', value)
      },
    })

    const viewedInvitationFilter = computed({
      get() {
        return store.getters['inviteList/getViewedInvitationFilter']
      },
      set(value) {
        store.commit('inviteList/setViewedInvitationFilter', value)
      },
    })

    const { fetchUserTable } = useUsersList()
    const resetAllFilters = () => {
      statusFilter.value = null
      groupsFilter.value = null
      specialMealsFilter.value = null
      transportFilter.value = null
      viewedInvitationFilter.value = null
      fetchUserTable()
    }

    const viewedInvitationFilterOptions = [
      { value: true, title: 'viewedInvitation' },
      { value: false, title: 'notViewedInvitation' },
    ]

    return {
      statusFilter,
      statusOptions,
      groupsFilter,
      specialMealsFilter,
      transportFilter,
      fetchUserTable,
      resetAllFilters,
      filteredStatus,
      viewedInvitationFilter,
      viewedInvitationFilterOptions,
      mealsTypes,
    }
  },
}
</script>
