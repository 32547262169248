import { guestStatusMapping } from '@/composables/useGuestStatuses'
import useSpecialMealsTypes from '@/composables/useSpecialMealsTypes'
import { loadLanguageAsync } from '@/plugins/i18n'
import useAppConfig from '@core/@app-config/useAppConfig'

const getDefaultState = () => ({
  // View-related params
  isInvitationPageLoading: true,
  isResponseLoading: false,
  isLoadingInvitationImage: true,
  loadStartTime: Date.now(),
  minimumLoadingTime: 1000,

  // Event-related params
  preview: false,
  meals: [],
  transports: [],
  names: {},
  locationName: '',
  locationGeo: '',
  date: '',
  img: '',
  lang: '',
  defaultLang: '',
  guestResponse: {},
  tourGuide: false,
  sendInviteeRequest: null,
  sendWrongNumberRequest: null,

  // Guest-related params
  guestStatus: null,
  numArrive: 1,
  selectedMeals: [],
  selectedMeal: '',
  selectedTransport: [],
  guestComment: '',
  showPlansChanged: false,
})

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    resetState(state) {
      Object.assign(state, getDefaultState())
    },
    updateStoreFromProps(state, props) {
      this.commit('invitationStore/setPreview', props.paramPreview)
      this.commit('invitationStore/setMeals', props.paramMeals)
      this.commit('invitationStore/setTransports', props.paramTransports)
      this.commit('invitationStore/setNames', props.paramNames)
      this.commit('invitationStore/setLocationName', props.paramLocationName)
      this.commit('invitationStore/setLocationGeo', props.paramLocationGeo)
      this.commit('invitationStore/setDate', props.paramDate)
      this.commit('invitationStore/setImg', props.paramImg)
      this.commit('invitationStore/setLang', props.paramLang)
      this.commit('invitationStore/setDefaultLang', props.paramDefaultLang)
      this.commit('invitationStore/setGuestResponse', props.paramGuestResponse)
      this.commit('invitationStore/setTourGuide', props.paramTourGuide)
      this.commit('invitationStore/setSendInviteeRequest', props.paramSendInviteeRequest)
      this.commit('invitationStore/setSendWrongNumberRequest', props.paramSendWrongNumberRequest)
    },

    // View-related mutations
    setIsInvitationPageLoading(state, value) {
      state.isInvitationPageLoading = value
    },
    setIsResponseLoading(state, value) {
      state.isResponseLoading = value
    },
    setIsLoadingInvitationImage(state, value) {
      state.isLoadingInvitationImage = value
    },
    setLoadStartTime(state, value) {
      state.loadStartTime = value
    },

    // Event-related mutations
    setPreview(state, value) {
      state.preview = value
    },
    setMeals(state, meals) {
      state.meals = meals
    },
    setTransports(state, transports) {
      state.transports = transports
    },
    setNames(state, names) {
      state.names = names
    },
    setLocationName(state, locationName) {
      state.locationName = locationName
    },
    setLocationGeo(state, locationGeo) {
      state.locationGeo = locationGeo
    },
    setDate(state, date) {
      state.date = date
    },
    setImg(state, img) {
      state.img = img
    },
    setLang(state, lang) {
      state.lang = lang
    },
    setDefaultLang(state, defaultLang) {
      state.defaultLang = defaultLang
    },
    setGuestResponse(state, guestResponse) {
      state.guestResponse = guestResponse
    },
    setTourGuide(state, tourGuide) {
      state.tourGuide = tourGuide
    },
    setSendInviteeRequest(state, fn) {
      state.sendInviteeRequest = fn
    },
    setSendWrongNumberRequest(state, fn) {
      state.sendWrongNumberRequest = fn
    },

    // Guest-related mutations
    setGuestStatus(state, value) {
      state.guestStatus = value
    },
    setNumArrive(state, value) {
      state.numArrive = value
    },
    setSelectedMeals(state, value) {
      state.selectedMeals.splice(0, state.selectedMeals.length, ...value)
    },
    setSelectedTransport(state, value) {
      state.selectedTransport = value
    },
    setGuestComment(state, value) {
      state.guestComment = value
    },
    setShowPlansChanged(state, value) {
      state.showPlansChanged = value
    },
  },
  actions: {
    async sendGuestResponse({ state, commit }) {
      commit('setIsResponseLoading', true)
      try {
        if (state.guestStatus === null || state.preview) {
          return
        }
        if (state.guestStatus === guestStatusMapping.NOT_COMING) {
          commit('setNumArrive', 0)
          commit('setSelectedTransport', [0])
          commit('setGuestComment', '')
        }

        await state.sendInviteeRequest({
          status: state.guestStatus,
          countOfGuests: state.numArrive,
          guestSpecialMeals: state.selectedMeals,
          guestTransports: [state.selectedTransport],
          comment: state.guestComment,
        })
      } finally {
        commit('setIsResponseLoading', false)
      }
    },
    async wrongNumber({ state, commit }) {
      if (state.preview) return
      commit('setIsResponseLoading', true)
      try {
        await state.sendWrongNumberRequest()
      } finally {
        commit('setIsResponseLoading', false)
      }
    },
    handleImageLoaded({ state, commit }) {
      const loadEndTime = Date.now()
      const timeSpentLoading = loadEndTime - state.loadStartTime
      const remainingTime = state.minimumLoadingTime - timeSpentLoading

      if (remainingTime > 0) {
        setTimeout(() => {
          commit('setIsLoadingInvitationImage', false)
        }, remainingTime)
      } else {
        commit('setIsLoadingInvitationImage', false)
      }
    },
    loadLangPage({ state }) {
      loadLanguageAsync(state.lang)
      const { isRtl } = useAppConfig()
      isRtl.value = state.lang === 'ar' || state.lang === 'he'
    },
    loadLastGuestResponse({ state, commit }) {
      // if guest already responded
      if (state.guestResponse && Object.keys(state.guestResponse).length > 0) {
        commit('setGuestStatus', state.guestResponse.status)
        commit('setNumArrive', state.guestResponse.countOfGuests > 0 ? state.guestResponse.countOfGuests : 1)
        commit('setSelectedTransport', state.guestResponse.guestTransports[0]?.name)
        const { mealsTypes } = useSpecialMealsTypes()
        const selectedMeals = state.guestResponse.specialMeal !== mealsTypes.NO_NEED_TO_ASK
          ? state.guestResponse.specialMeals.map(meal => meal.id)
          : []

        commit('setSelectedMeals', selectedMeals)
        commit('setShowPlansChanged', true)
      }
    },
  },
}
