<template>
  <div>
    <v-card
      color="transparent"
      class="form-card mb-6"
      min-height="150px"
      max-height="1000px"
      flat
    >
      <v-card-text>
        <v-form
          ref="step1Form"
        >
          <v-row
            align="center"
            justify="center"
          >
            <!-- select languages -->
            <v-col
              cols="12"
              md="9"
            >
              <v-select
                v-model="stepData.eventLanguages"
                :item-disabled="isItemDisabled"
                :items="LanguageList"
                :menu-props="['offset-y','closeOnContentClick']"
                :rules="[validators.required]"
                :label="$t('EventLanguages')"
                :item-text="item => $t(item.lang)"
                item-value="lang"
                required
                return-object
                dense
                hide-details
                outlined
                rounded
                multiple
                class="justify-center align-center"
                @change="eventLanguagesChanged"
              >
                <template #prepend>
                  <v-avatar
                    size="36"
                    class="v-avatar-light-bg primary--text"
                  >
                    <v-icon
                      color="primary"
                      size="26"
                    >
                      {{ icons.mdiWeb }}
                    </v-icon>
                  </v-avatar>
                </template>
              </v-select>
            </v-col>

            <!-- if selected more than one language, display default language -->
            <v-col
              v-if="stepData.eventLanguages.length > 1"
              cols="12"
              md="9"
            >
              <v-select
                v-model="stepData.defaultLang"
                :items="stepData.eventLanguages"
                :item-text="lang => $t(lang.lang)"
                item-value="lang"
                :menu-props="['offset-y']"
                :rules="[validators.required]"
                :label="$t('DefaultLanguage')"
                required
                dense
                class="justify-center align-center"
                hide-details
                outlined
                rounded
              >
                <template #prepend>
                  <v-avatar
                    size="36"
                    class="v-avatar-light-bg primary--text"
                  >
                    <v-icon
                      size="26"
                      color="primary"
                    >
                      {{ icons.mdiWeb }}
                    </v-icon>
                  </v-avatar>
                </template>
              </v-select>
            </v-col>

            <!-- for each selected language, fill event name -->
            <v-col
              v-for="item in stepData.eventLanguages"
              :key="item.lang"
              cols="12"
              md="9"
            >
              <v-text-field
                v-model="stepData.langEventName[item.lang]"
                :label="`${$t('EventNamePerLang')}${$t(item.lang)}`"
                :rules="[validators.maxLenInput, validators.required, validators.minLenInput]"
                :placeholder="$t('ExampleEventName', item.lang)"
                maxlength="41"
                required
                dense
                hide-details="auto"
                class="justify-center align-center"
                outlined
                rounded
                multiple
              >
                <template #prepend>
                  <v-avatar
                    size="36"
                    class="v-avatar-light-bg primary--text"
                  >
                    <v-icon
                      size="26"
                      color="primary"
                    >
                      {{ icons.mdiPencil }}
                    </v-icon>
                  </v-avatar>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <div
      class="d-flex justify-end"
    >
      <v-btn
        color="primary"
        class="me-2"
        rounded
        @click="validateForm()"
      >
        {{ $t('Next') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { maxLenInput, minLenInput, required } from '@core/utils/validation'
// eslint-disable-next-line object-curly-newline
import useLanguages from '@/composables/useLanguages'
// eslint-disable-next-line object-curly-newline
import { mdiCalendar, mdiMapMarker, mdiPencil, mdiWeb } from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
  props: {
    eventData: {
      default: null,
      type: Object,
    },
    moveToNextStep: {
      type: Function,
      default: null,
    },
    notAvailableLang: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const step1Form = ref(null)
    const { LanguageList } = useLanguages()
    const isItemDisabled = item => (props.notAvailableLang ? props.notAvailableLang.includes(item.lang) : false)

    const stepData = ref({
      eventLanguages: props?.eventData?.languages ?? [],
      langEventName: props?.eventData?.names ?? {},
      defaultLang: props?.eventData?.defaultLang ?? '',
    })
    const eventLanguagesChanged = item => {
      // if selected one language, the default language will the same as selected.
      if (item.length === 1) {
        stepData.value.defaultLang = item.map(el => el.lang).join()
      } else {
        stepData.value.defaultLang = ''
      }
    }

    const validateForm = () => {
      if (step1Form.value.validate()) {
        // eslint-disable-next-line object-curly-newline
        const { eventLanguages, langEventName, defaultLang } = stepData.value

        // Check if the event language does not exist, and the event name exists in that language.
        const langIncludeName = Object.keys(stepData.value.langEventName).filter(
          el => !stepData.value.eventLanguages.map(lang => lang.lang).includes(el),
        )
        langIncludeName.forEach(lang => {
          delete stepData.value.langEventName[lang]
        })

        props.moveToNextStep({
          languages: eventLanguages,
          names: langEventName,
          defaultLang: defaultLang !== '' ? defaultLang : props?.eventData?.languages[0].lang,
        })
      }
    }

    return {
      step1Form,
      validateForm,
      stepData,
      LanguageList,
      eventLanguagesChanged,
      isItemDisabled,

      validators: {
        required,
        maxLenInput,
        minLenInput,
      },
      icons: {
        mdiCalendar,
        mdiMapMarker,
        mdiPencil,
        mdiWeb,
      },
    }
  },
}
</script>
<style scoped>
::v-deep .v-time-picker-title {
  justify-content: center;
}
::v-deep .v-picker__actions {
  align-self: center;
}
</style>
