<template>
  <InvitationPage
    v-if="guestData"
    :key="`guestData-${guestData.date}`"
    :param-preview="false"
    :param-meals="meals"
    :param-transports="trans"
    :param-names="guestData.names"
    :param-location-name="guestData.location.eventLocationName"
    :param-location-geo="guestData.location"
    :param-date="eventDate"
    :param-img="getFullImageUrl(guestData.image)"
    :param-lang="guestData.lang"
    :param-guest-response="guestData.guestState"
    :param-send-invitee-request="sendResponse"
    :param-send-wrong-number-request="wrongNumber"
    :param-tour-guide="false"
    :param-default-lang="guestData.lang"
  ></InvitationPage>
</template>

<script>
import InvitationPage from '@/components/Invitation/InvitationPage.vue'
import { i18n } from '@/plugins/i18n/index'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
import { useRouter } from '@core/utils'
import { ref } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
// eslint-disable-next-line import/no-cycle
import { getFullImageUrl } from '@/@core/utils/ImageFromApi'
import router from '@/router'

export default {
  components: { InvitationPage },
  setup() {
    const { route } = useRouter()
    const guestData = ref()
    const meals = ref()
    const eventDate = ref()
    const trans = ref()
    const toast = useToast()
    const showToast = () => toast.error(i18n.t('errorManageEvents'))
    const wrongNumberStatusOk = ref()

    const getGuestDetails = () => {
      store
        .dispatch('getInvite', { guestID: route.value.params.id })
        .then(response => {
          guestData.value = {
            ...response.data,
          }
          eventDate.value = guestData.value.date
          // eslint-disable-next-line operator-linebreak
          meals.value =
            guestData.value.specialMeals.length > 0 ? guestData.value.specialMeals.filter(el => el.value !== 0) : ''
          trans.value = guestData.value.transports.length > 0 ? guestData.value.transports.map(el => el.name) : ''
        })
        .catch(() => {
          showToast()
          router.push({ name: 'error-404' })
        })
    }
    getGuestDetails()

    const sendResponse = async data => {
      try {
        const res = await store.dispatch('postInvite', {
          guestID: route.value.params.id,
          responseData: {
            status: data.status,
            countOfGuests: data.countOfGuests,
            guestSpecialMeals: data.guestSpecialMeals,
            guestTransports: guestData.value.transports
              .filter(el => el.name === data.guestTransports[0])
              .map(el => el.id),
            comment: data.comment,
          },
        })
        if (res.status !== 201) {
          throw new Error('Bad response')
        }
        getGuestDetails()
      } catch {
        showToast()
      }
    }

    const wrongNumber = async () => {
      try {
        const res = await store.dispatch('wrongNumberComment', { guestID: route.value.params.id })
        wrongNumberStatusOk.value = res.status === 200
        getGuestDetails()
      } catch {
        showToast()
      }
    }

    return {
      guestData,
      meals,
      trans,
      sendResponse,
      wrongNumber,
      eventDate,
      wrongNumberStatusOk,
      getFullImageUrl,
    }
  },
}
</script>
