<template>
  <v-row
    v-if="guestStatus === guestStatusMapping.COMING"
    style="text-align-last: center"
    align="center"
    justify="center"
    dir="rtl"
    class="mt-2"
  >
    <!-- Waze -->
    <v-col
      cols="4"
      md="4"
      style="min-width: fit-content"
    >
      <v-card
        color="transparent"
        outlined
        flat
        @click="openNavigation"
      >
        <v-card-title
          class="align-center justify-center"
          style="margin-top: -6px"
        >
          <v-icon size="38">
            {{ icons.mdiMapMarker }}
          </v-icon>
        </v-card-title>
        <v-card-subtitle>
          <span class="font-weight-semibold"> {{ $t('Navigation') }}</span>
        </v-card-subtitle>
      </v-card>
    </v-col>

    <!-- Google Calendar -->
    <v-col
      cols="4"
      md="4"
      style="min-width: fit-content"
    >
      <v-card
        color="transparent"
        outlined
        flat
      >
        <v-card-title class="align-center justify-center mb-1">
          <add-to-calendar
            :title="eventName"
            :location="locationName"
            :start="dateStart"
            :end="dateStart"
            inline-template
          >
            <div>
              <google-calendar id="google-calendar">
                <link
                  v-if="!tourGuide"
                  rel="stylesheet"
                  href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
                />
                <button type="button">
                  <i
                    class="fa fa-calendar-check-o styleButton"
                    style="font-size: 1.4em; color: #8a8d93"
                  ></i>
                </button>
              </google-calendar>
            </div>
          </add-to-calendar>
        </v-card-title>
        <v-card-subtitle>
          <span class="font-weight-semibold"> {{ $t('AddToCalendar') }}</span>
        </v-card-subtitle>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { guestStatusMapping } from '@/composables/useGuestStatuses'
import { i18n } from '@/plugins/i18n/index'
import store from '@/store'
import { useRouter } from '@core/utils'
import { mdiMapMarker } from '@mdi/js'
import { computed } from '@vue/composition-api'

export default {
  setup() {
    const { route, router } = useRouter()
    const state = store.state.invitationStore

    const guestStatus = computed(() => state.guestStatus)
    const tourGuide = computed(() => state.tourGuide)
    const lang = computed(() => state.lang)
    const locationName = computed(() => state.locationName)
    const dateStart = computed(() => {
      const eventDate = new Date(state.date)

      return new Date(
        eventDate.getFullYear(),
        eventDate.getMonth(),
        eventDate.getDate(),
        eventDate.getHours(),
        eventDate.getMinutes(),
      )
    })

    const eventName = computed(() => state.names[i18n.locale] || state.names[state.lang] || state.names[state.defaultLang])
    const locationGeo = computed(() => state.locationGeo)
    const preview = computed(() => state.preview)

    const openNavigation = () => {
      if (tourGuide.value) return
      const queryParams = {
        lang: lang.value,
        location: locationGeo.value,
        invitation: true,
        preview: preview.value,
      }

      if (!preview.value) {
        router.push({ name: 'guest-navigation', params: { guestID: route.value.params.id } })
      } else {
        router.push({ name: 'preview-navigation', query: queryParams })
      }
    }

    const icons = {
      mdiMapMarker,
    }

    return {
      guestStatus,
      guestStatusMapping,
      openNavigation,
      icons,
      eventName: eventName.value,
      locationName: locationName.value,
      dateStart: dateStart.value,
      tourGuide: tourGuide.value,
    }
  },
}
</script>

<style scoped>
.styleButton {
  color: #8a8d93 !important;
}
.v-application a {
  color: #8a8d93 !important;
}
.fa {
  color: #8a8d93 !important;
}
</style>
